import React from 'react'
import { Link } from 'react-router-dom';
import Admin from './Admin';

function Access() {
    const isAuthorize = localStorage.getItem("isLoggedIn");
    return (
        <>
            {isAuthorize ? <Admin /> : (
                <div>
                    <h2>Vous n'avez pas accès à cette page !</h2>
                    <Link to="/login">Cliquez ici pour vous connecter</Link>
                </div>
            )}
        </>
    )
}

export default Access