import './App.css';
import Login from './Pages/Login'
import { //Permet la gestion de la navigation entre différents url
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Access from './Pages/Access';

function App() {

  return (
    <>
      <Router>
        <Routes>
          {/* Route permet d'activer un composant en fonction de l'URL
               Navigate redirige vers un nouvel URL
          */}
          <Route path="/" element={localStorage.getItem("isLoggedIn") ? <Navigate to="/admin" /> : <Navigate to="/login" />} />
          <Route path="/login" element={localStorage.getItem("isLoggedIn") ? <Navigate to="/admin" /> : <Login />} />
          {/* <Route path="/admin" element={<Access />} /> */}
          <Route path="/admin" element={localStorage.getItem("isLoggedIn") ? <Access /> : <Navigate to="/login" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
