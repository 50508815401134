import React, { useEffect, useState } from 'react'
import "./Admin.css"
import TaskRow from "../Components/TaskRow"
import { GrAddCircle } from 'react-icons/gr'



function Admin() {

  const [tasks, setTasks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [idSelected, setidSelected] = useState(0);
  const [newCategory, setNewCategory] = useState(0);
  const [newCatText, setNewCatText] = useState("")
  const [categoryExistErreur, setCategoryExistErreur] = useState(0)

  useEffect(() => {
    fetch("/getData", { //Requête au serveur sur le port 5000. Active 'App.post("/postData", async (req, res) => {' sur le serveur
      method: "GET",
    })
      .then(response => response.json()) //On précise le type de données reçu. 
      .then(responses => {
        // On isole toutes les catégories existantes
        let newCategories = responses.data.map(elem => elem.category)
        //On supprime les catégories en double
        newCategories = newCategories.filter((item, index) => newCategories.indexOf(item) === index);
        setCategories(newCategories);
        setTasks(responses.data);
      })
  }, [])

  function handleClickGeneral(e) {
    setidSelected(e.target.id)
  }

  function addTask(category) {
    let formData = new URLSearchParams();
    formData.append('category', category);
    fetch("/addTask", {
      method: "POST",
      body: formData //Envoi des données
    })
      .then(response => response.json()) //On précise le type de données reçu. 
      .then(data => {
        if (data.status) {
          let newTab = tasks
          let line = { id: data.resultat, name: " ", createdAt: new Date(), category: category, isDone: 0, isNew: 1 }
          newTab.push(line)
          setTasks(newTab)
          console.log(tasks)
          setidSelected(data.resultat)

        }
      })
  }

  function handleClickAddTask(e) {
    let category = e.target.id;
    if (e.target.tagName == "path") {
      category = e.target.parentElement.id
    }
    addTask(category)

  }



  function handleNewCategory(e) { //Vérifier que la catégorie n'existe pas !!!!
    let identique = 0;
    categories.forEach(elem => {
      console.log(elem)
      if (elem == newCatText) {
        identique = 1;
      }

    })
    if (!identique) {
      let nouvelleCategorie = categories
      nouvelleCategorie.push(newCatText)
      setCategories(nouvelleCategorie)
      addTask(newCatText)
      setNewCatText("")
      setNewCategory(0)
      setCategoryExistErreur(0)
    }
    else {
      setCategoryExistErreur(1);

    }

  }
  function handleAddCategory(e) {
    setNewCatText(e.target.value)


  }
  function handleButtonNewCat(e) {
    setNewCategory(1)
  }

  return (
    <div id="admin-body" className="admin-body" onClick={handleClickGeneral}>
      <h2 id="admin-title" className="admin-title" onClick={handleClickGeneral}>To Do List</h2>



      {
        categories.map((category, index) => {
          return (
            <fieldset id={"category"} key={index} className="categoryField">
              <legend className='categoryName'> {category} </legend>
              {
                tasks.map((task, taskIndex) => {
                  if (task.category == category) {
                    let dateformat = new Date(task.createdAt).toLocaleDateString('fr');
                    return <TaskRow key={task.id} id={task.id} date={dateformat} name={task.name} isChecked={task.isDone} idSelected={idSelected} isNew={task.isNew ? 1 : 0} />
                  }
                  else {
                    return ""
                  }
                })
              }
              <div id={category} className="button-container" onClick={handleClickAddTask}><GrAddCircle id={category} className='button-add' /></div>




            </fieldset>
          )
        })
      }
      <fieldset className="categoryField new-cat">
        {
          newCategory ? <div>
            <input name="category" class="input-add-cat" type="text" onChange={handleAddCategory} /><br />
            {
              categoryExistErreur ? <div className="erreurCategory">Cette catégorie existe déjà</div> : <></>

            }
            <button type="submit" onClick={handleNewCategory}>Valider nouvelle catégorie</button>

          </div> : <></>
        }

        <GrAddCircle className='button-add' onClick={handleButtonNewCat} />



      </fieldset>
    </div>
  )
}

export default Admin