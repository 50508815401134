import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import './Login.css';

function Login() {

  const navigate = useNavigate();

  const [displayInfo, setDisplayInfo] = useState("") //Gestion de variables d'états.
  const handleSubmit = (e) => { //Validation du formulaire
    e.preventDefault();               //Evite l'envoi du formulaire
    let pseudo = e.target[0].value;   //on récupère les champs pseudo et password
    let password = e.target[1].value;

    let formData = new URLSearchParams(); //On encode les données dans une URL pour l'envoyer au serveur
    formData.append('pseudo', pseudo);
    formData.append('password', password);

    //Cf proxy dans package.json. Si la page n'est pas trouvée dans le dossier, il est ajouté  http://localhost:5000/postData (voir proxy)
    fetch("/postData", { //Requête au serveur sur le port 5000. Active 'App.post("/postData", async (req, res) => {' sur le serveur
      method: "POST",
      body: formData //Envoi des données
    })
      .then(response => response.json()) //On précise le type de données reçu. 
      .then(data => { //Reponse du serveur
        if (data.status) {
          localStorage.setItem("isLoggedIn", true); //LocalStorage mémorise qu'on est déjà enregistré
          window.location.reload(); //On renvoie vers la page admin. navigate est utilisé dans le code. C'est l'équivalent du composant <Navigate to=''>
        }

        if (!data.status) { //En cas de message d'erreur du serveur
          switch (data.reason) {
            case 'bdd': //Pb dans l'ouverture de la base de donnée
              setDisplayInfo("Une erreur est survenue lors de l'accès à la base de donnée");
              break;
            case 'login': //pb de login
              setDisplayInfo("L'identifiant est incorrect !");
              break;
            case 'password':
              setDisplayInfo("Le mot de passe est incorrect !");
              break;
            default:
              setDisplayInfo("Une erreur est survenue");
              break
          }
        }
      })
  }

  return (
    <div className="login-container">
      <h2>Se connecter</h2>
      <form method="POST" onSubmit={handleSubmit}>
        <div>
          <input placeholder="Nom d'utilisateur" type="text" id="username" name="username" required />
        </div>
        <div>
          <input placeholder="Mot de passe" type="password" id="password" name="password" required />
        </div>
        <button type="submit">Se connecter</button>
      </form>
      {displayInfo.length > 0 && <p className='info-login'>{displayInfo}</p>} {/* Afficher le message d'erreur  */}

    </div>
  )
}

export default Login