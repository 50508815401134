import React, { useEffect, useState } from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'

import './TaskRow.css'

function TaskRow({ id, name, date, isChecked, idSelected, isNew }) {

    const [checked, setChecked] = useState(isChecked)
    const [modeEdit, setModeEdit] = useState(0)
    const [isDelete, setDeleted] = useState(0)
    const [textName, setTextName] = useState(name)
    const [newElement, setNew] = useState(isNew)


    useEffect(() => {
        if (newElement) {
            setModeEdit(1);
            setNew(0)
            idSelected = id;
            console.log(modeEdit)
        }
    })
    if (modeEdit && id != idSelected) {
        endEdit(id)
    }

    function endEdit(id) {
        setModeEdit(0)
    }

    function handleChange(e) {
        //console.log(e.target)
        if (!modeEdit) {
            let newcheck = !checked
            setChecked(newcheck)
            let formData = new URLSearchParams(); //On encode les données dans une URL pour l'envoyer au serveur
            formData.append('checked', newcheck);
            formData.append('id', e.target.id);


            //Cf proxy dans package.json. Si la page n'est pas trouvée dans le dossier, il est ajouté  http://localhost:5000/postData (voir proxy)
            fetch("/changeChecked", { //Requête au serveur sur le port 5000. Active 'App.post("/postData", async (req, res) => {' sur le serveur
                method: "POST",
                body: formData //Envoi des données
            })
                .then(response => response.json()) //On précise le type de données reçu. 
                .then(data => { console.log(data) })
        }
    }

    function handleClick(e) {
        handleChange(e)
    }

    function handleClickEdit(e) {
        if (modeEdit) {
            endEdit(e.target.id)
        }
        else {

            setModeEdit(1)
            console.log(modeEdit)
        }

    }

    function handleChangeInput(e) {

        let formData = new URLSearchParams();
        formData.append('newText', e.target.value);
        formData.append('id', e.target.id)


        fetch("/newText", { //Requête au serveur sur le port 5000. Active 'App.post("/postData", async (req, res) => {' sur le serveur
            method: "POST",
            body: formData //Envoi des données
        })
            .then(response => response.json()) //On précise le type de données reçu. 
            .then(data => {
                console.log(data)
                if (data.status) {
                    setTextName(e.target.value)
                }
            })

    }

    function handleClickDelete(e) {

        if (e.target.tagName == "path") {
            id = e.target.parentElement.id
        }
        else {
            id = e.target.id
        }

        let formData = new URLSearchParams();
        formData.append('id', id)


        fetch("/deleteTask", { //Requête au serveur sur le port 5000. Active 'App.post("/postData", async (req, res) => {' sur le serveur
            method: "POST",
            body: formData //Envoi des données
        })
            .then(response => response.json()) //On précise le type de données reçu. 
            .then(data => {
                console.log(data)
                if (data.status) {
                    setDeleted(1);
                }
            })



    }

    {
        if (isDelete) {
            return (<></>)
        }
        else {
            return (

                <div className={checked && !modeEdit ? "List-container-checked" : "List-container"} id={id}  >

                    <div id={id} className={checked && !modeEdit ? "task-text-checked" : "task-text"} onClick={handleClick}>
                        <div id={id} className="task-checkbox">
                            <input id={id} className="task-checkinput" type="checkbox" name="isDone" onChange={handleChange} checked={checked} defaultChecked={checked} />
                        </div>
                        <div id={id} className="task-name">{modeEdit ? <input id={id} input={id} defaultValue={textName} autoFocus onChange={handleChangeInput} /> : textName}</div>


                    </div>
                    <div id={id} className="task-delete">
                        <div id={id} className="task-date">{date}</div>
                        <FiEdit id={id} className="edit" onClick={handleClickEdit} />
                        <FaTrashAlt id={id} className="delete" onClick={handleClickDelete} />

                    </div>
                </div>
            )
        }
    }

}

export default TaskRow